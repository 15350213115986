.rio-carousel {
    position: relative;
    overflow: hidden;
    width: var(--rio-carousel-width);
    height: var(--rio-carousel-height);
}

.rio-carousel_item {
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
    transition: var(--rio-carousel-speed) ease-out;
}

.rio-carousel_item--no_animation {
    transition: none;
}
